/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseDetail } from '../models/ResponseDetail';
import type { VehicleDomainRequest } from '../models/VehicleDomainRequest';
import { request as __request } from '../core/request';

export class DomainReadControllerService {

    /**
     * getBanks
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getBanksUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/eft/banks`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getCitiesByFederativeUnit
     * @param federativeUnit federative_unit
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getCitiesByFederativeUnitUsingGet(
        federativeUnit: string,
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/city/${product}/federative_unit/${federativeUnit}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getCivilStates
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getCivilStatesUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/marital_status/${product}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getFederativeUnits
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getFederativeUnitsUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/federation_unit/${product}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getFuelTypes
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getFuelTypesUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/vehicle_fuel_type/${product}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getNationalities
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getNationalitiesUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/nationality/${product}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getProfessionalTypes
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getProfessionalTypesUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/profession_type/${product}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getProfessions
     * @param product product
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getProfessionsUsingGet(
        product: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/occupation/${product}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getBrands
     * @param product product
     * @param request request
     * @returns ResponseDetail OK
     * @returns any Created
     * @throws ApiError
     */
    public static async getBrandsUsingPost(
        product: string,
        request: VehicleDomainRequest,
    ): Promise<Array<ResponseDetail> | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/domain/search/vehicle_brand/${product}`,
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getModels
     * @param product product
     * @param request request
     * @returns ResponseDetail OK
     * @returns any Created
     * @throws ApiError
     */
    public static async getModelsUsingPost(
        product: string,
        request: VehicleDomainRequest,
    ): Promise<Array<ResponseDetail> | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/domain/search/vehicle_model/${product}`,
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getVersions
     * @param product product
     * @param request request
     * @returns ResponseDetail OK
     * @returns any Created
     * @throws ApiError
     */
    public static async getVersionsUsingPost(
        product: string,
        request: VehicleDomainRequest,
    ): Promise<Array<ResponseDetail> | any> {
        const result = await __request({
            method: 'POST',
            path: `/api/personal_loans/domain/search/vehicle_version/${product}`,
            body: request,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * getByZipCode
     * @param product product
     * @param zipCode zip_code
     * @returns ResponseDetail OK
     * @throws ApiError
     */
    public static async getByZipCodeUsingGet(
        product: string,
        zipCode: string,
    ): Promise<Array<ResponseDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/domain/cep_address/${product}/zip_code/${zipCode}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}